import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';
import LogoCollapse from '../../components/LogoCollapse';
import { useAuth0 } from '@auth0/auth0-react';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}

const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	const [isPremium, setIsPremium] = useState<boolean>(false);
	const { user } = useAuth0();
	useEffect(() => {
		const fetchUserMetadata = async () => {
			const metadata = user?.['https://gradly-authentication-server/app_metadata'] || {};

			if (metadata && metadata.subscription_expires_at) {
				const now = new Date();
				const expiration = new Date(metadata.subscription_expires_at * 1000);
				// console.log("Subscription expires at: ", expiration);
				if (expiration > now) {
					setIsPremium(true);
				} else {
					setIsPremium(false);
				}
			} else {
				setIsPremium(false);
			}
		};

		fetchUserMetadata();
	}, [user]);

	return (
		<div className='brand'>
			{asideStatus && (
				<div className='brand-logo' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '32px', }}>
					<h1 className='brand-title' style={{ margin: 0 }}>
						<Link to='/' aria-label='Logo'>
							<Logo height={32} className="sidebar-logo mt-3" isPremium={isPremium} />
						</Link>
					</h1>
				</div>
			)}
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};

Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
