import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import UpgradePrompt from '../../../layout/Aside/UpgradePrompt';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus, mobileDesign } = useContext(ThemeContext);
	const navigate = useNavigate();
	const { user } = useAuth0();
	const [isPremium, setIsPremium] = useState<boolean>(false);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	useEffect(() => {
		const fetchUserMetadata = () => {
			const metadata = user?.['https://gradly-authentication-server/app_metadata'] || {};
			if (metadata && metadata.subscription_expires_at) {
				const now = new Date();
				const expiration = new Date(metadata.subscription_expires_at * 1000);
				if (expiration > now) {
					setIsPremium(true);
				} else {
					setIsPremium(false);
				}
			} else {
				setIsPremium(false);
			}
		};

		fetchUserMetadata();
	}, [user]);

	const handleUpgradeClick = () => {
		if (mobileDesign) {
			setAsideStatus(false); // Close the aside only on mobile
		}
		navigate('/upgrade');
	};

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{!doc && (
					<>
						<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
						{asideStatus && !isPremium && <UpgradePrompt onUpgradeClick={handleUpgradeClick} />}
					</>
				)}

			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
