// Logo.tsx
import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Import the logo image
import logo from '../assets/logo.png';
import gradlyprologo from '../assets/gradlyprologo.png';

interface ILogoProps {
  width?: number;
  height?: number;
  className?: string; // Allow for custom class names
  isPremium?: boolean;
}

const Logo: FC<ILogoProps> = ({ width, height, className, isPremium }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className={className}
    >
      <img
        src={isPremium ? gradlyprologo : logo}
        alt="Logo" // Provide an alt text for accessibility
        style={{
          width: width ? `${width}px` : '150px', // Set a default width
          height: height ? `${height}px` : 'auto', // Maintain aspect ratio
          objectFit: 'contain',
          maxWidth: '100%', // Ensure responsiveness
        }}
      />
    </div>
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  isPremium: PropTypes.bool,
};

export default Logo;

