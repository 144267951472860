import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import './i18n';

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);
root.render(
	//<React.StrictMode>
	  <BrowserRouter>
		<Auth0ProviderWithNavigate>
			<ThemeContextProvider>
				<App />
			</ThemeContextProvider>
		</Auth0ProviderWithNavigate>
	  </BrowserRouter>
	//</React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
