// UpgradePrompt.tsx

import React from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';

type UpgradePromptProps = {
	onUpgradeClick: () => void;
  };
  
  const UpgradePrompt: React.FC<UpgradePromptProps> = ({ onUpgradeClick }) => {
	return (
    <Card className="upgrade-prompt m-3 text-center">
      <CardBody>
	  	<div className="d-flex align-items-center justify-content-center mb-3">
          <div className="sparkle-icon me-2" style={{ fontSize: '1.5rem' }}>✨</div>
          <p className="fw-bold mb-0" style={{ textAlign: 'left' }}>Upgrade to Gradly Pro now!</p>
        </div>
        <Button
          style={{
            backgroundColor: '#FF8343',
            borderColor: '#FF8343',
            color: '#fff',
			whiteSpace: 'nowrap',
          }}
		  onClick={onUpgradeClick}
        >
          Upgrade now
        </Button>
      </CardBody>
    </Card>
  );
};

export default UpgradePrompt;
