// LoadingSpinner.tsx
import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { FadeLoader } from 'react-spinners'; // Import the loader from react-spinners

interface LoadingSpinnerProps {
  title?: string; // Optional title prop
  loading: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ title = "Loading...", loading }) => {
  return (
    <PageWrapper title={title}>
      <Page container="fluid">
      <div
          className="d-flex justify-content-center"
          style={{ marginTop: '40vh', transform: 'translateY(-50%)' }}
        >
          <FadeLoader color="#FF8343" loading={loading} height={30} width={8} radius={4} margin={15} />
        </div>
      </Page>
    </PageWrapper>
  );
};

export default LoadingSpinner;
